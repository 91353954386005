.accordion-button:not(.collapsed) {
  color: #ffffff;
  background-color: #198754;
  box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
  background-image: none;
}
modal-content {
  background-color: 'transparent';
  border: none;
}
input {
  margin: '0px 20px';
}
[role='tab'] {
  color: #198754;
}
[role='tab']:hover {
  color: #115032;
}
[aria-selected='true'] {
  color: #ffffff !important;
  background-color: #198754 !important;
}
